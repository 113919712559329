import React, { useEffect } from 'react'
import anime from 'animejs'
import styled from 'styled-components'
import Hovertip from './Hovertip'
import { COLORS } from './../constants'

const Text = styled.h1`
  cursor: ${(props) => (props.plain ? `auto` : `help`)};
  color: ${COLORS.BLUE};
  font-family: 'Hurricane', cursive;
  font-size: 8rem;
  font-weight: 800;
  margin: 0;
  text-shadow: 0 0 20px ${COLORS.PURPLE}, 1px 1px 10px ${COLORS.PURPLE},
    -1px -1px 5px ${COLORS.PURPLE}, 1px -1px 15px ${COLORS.PURPLE},
    -1px 1px 0 ${COLORS.PURPLE};

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`

const Title = ({ plain }) => {
  useEffect(() => {
    if (!plain) {
      // Wrap every letter in a span
      const textWrapper = document.querySelector('#animate')
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      )

      // Animate the display of each letter
      anime.timeline({ loop: false }).add({
        targets: '#animate .letter',
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        duration: 1800,
        delay: (el, i) => 150 * (i + 1),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // empty dependency array needed to prevent title re-render when entering passcode

  if (plain) {
    return <Text plain>joseph loves alicia</Text>
  }

  return (
    <Hovertip content='How it all began'>
      <Text id='animate'>joseph loves alicia</Text>
    </Hovertip>
  )
}

export default Title
