import React from 'react'
import styled from 'styled-components'
import Appear from '../Animations/Appear'
import Theme from '../../Theme'

const HeadlineWrapper = styled.div`
  margin: 1.5rem 0;
  scroll-margin-top: ${Theme.navbar.height.get()};
  scroll-snap-align: center;
  scroll-snap-stop: always;
`

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.05;
  margin: 1.5rem 0;
`

const Headline = ({ children }) => (
  <Appear>
    <HeadlineWrapper>
      <Title>{children}</Title>
    </HeadlineWrapper>
  </Appear>
)

export default Headline
