import React from 'react'
import styled from 'styled-components'
import ButtonBar from './ButtonBar'
import ConnectionsBoard from './ConnectionsBoard'
import MistakeTracker from './MistakeTracker'

const Directions = styled.h2`
  font-weight: 500;
  text-align: center;
`

const ConnectionsGame = () => (
  <>
    <Directions>Create four groups of four!</Directions>
    <ConnectionsBoard />
    <MistakeTracker />
    <ButtonBar />
  </>
)

export default ConnectionsGame
