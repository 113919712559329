import { createContext, useContext, useReducer } from 'react'
import { ACTIONS, ACTIVITIES } from '../../constants'

const NavContext = createContext(null)

const NavDispatchContext = createContext(null)

export function NavProvider({ children }) {
  const [state, dispatch] = useReducer(navReducer, initialNav)

  return (
    <NavContext.Provider value={state}>
      <NavDispatchContext.Provider value={dispatch}>
        {children}
      </NavDispatchContext.Provider>
    </NavContext.Provider>
  )
}

export function useNav() {
  return useContext(NavContext)
}

export function useNavDispatch() {
  return useContext(NavDispatchContext)
}

function navReducer(state, action) {
  switch (action.type) {
    case ACTIONS.NAV.SELECT_ACTIVITY:
      return { ...state, activity: action.activity }
    case ACTIONS.NAV.SET_AUDIO:
      return { ...state, audio: action.audio }
    default:
      return state
  }
}

const initialNav = {
  audio: null,
  page: ACTIVITIES.DASHBOARD,
}
