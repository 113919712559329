import styled from 'styled-components'

const RoundedButton = styled.button`
  background-color: transparent;
  border: 1px solid black;
  border-radius: 32px;
  cursor: pointer;
  font-size: 16px;
  padding: 16px;

  &.black {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
  }

  &:disabled {
    background-color: transparent;
    border-color: rgb(127, 127, 127);
    color: rgb(127, 127, 127);
    cursor: auto;
  }
`

export default RoundedButton
