import React from 'react'
import styled from 'styled-components'
import Appear from '../Animations/Appear'
import Joseph from './../../assets/images/joseph.jpg'

const Author = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  margin: 2rem 0 4.5rem;
`

const Image = styled.img`
  border-radius: 100%;
  margin-right: 10px;
  height: 56px;
  width: 56px;
`

const Avatar = () => {
  return (
    <Appear duration={1600}>
      <Container>
        <Image src={Joseph} />
        <div>
          by <Author>Joseph May</Author>
          <br />
          Alicia's Husband
        </div>
      </Container>
    </Appear>
  )
}

export default Avatar
