import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/pro-solid-svg-icons'
import Appear from '../Animations/Appear'
import BackgroundImage from '../BackgroundImage'
import FullPageWrapper from '../FullPageWrapper'
import Title from '../Title'
import { PASSCODE } from '../../constants'

const PasscodeInputWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
`

const ShapeWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 1rem;
  height: 9rem;
  justify-content: center;
  position: relative;
  width: 9rem;

  & svg {
    opacity: 0.9;
    position: absolute;
    z-index: -100;
  }

  @media (max-width: 768px) {
    font-size: 0.45rem;
    height: 4rem;
    width: 4rem;
  }
`

const StyledPasscodeInput = styled.input`
  background: transparent;
  border: none;
  color: goldenrod;
  font-family: 'ProximaNova', 'Roboto', sans-serif;
  font-size: 2.8rem;
  font-weight: 800;
  height: 4rem;
  text-align: center;
  width: 4rem;

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`

const PasscodeInput = ({ id, onChange, onDelete, onFocus, value }) => (
  <Appear duration={3000} percent='-50%'>
    <ShapeWrapper>
      <StyledPasscodeInput
        autoCapitalize='none'
        autoFocus={id === 0 ? true : false}
        id={`passcode-${id}`}
        maxLength={1}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onDelete}
        type='text'
        value={value}
      />
      <FontAwesomeIcon icon={faHeart} size='8x' inverse />
    </ShapeWrapper>
  </Appear>
)

const LoginForm = ({ onChange, onSubmit }) => {
  const [passcodeKeys, setPasscodeKeys] = useState(
    Array(PASSCODE.length).fill('')
  )

  const handleChange = (event) => {
    const index = event.target.id.split('-')[1]
    const newKeys = [...passcodeKeys]
    newKeys.splice(index, 1, event.target.value)
    setPasscodeKeys(newKeys)
    onChange(newKeys.join(''))
  }

  const handleDelete = (event) => {
    if (event.keyCode === 8) setFocus(passcodeKeys, true)
  }

  const handleFocus = (event) => {
    if (event.target.value === '') setFocus(passcodeKeys)
  }

  const setFocus = (keys, doDelete = false) => {
    let emptyIndex = keys.findIndex((key) => key === '')
    if (doDelete) emptyIndex--
    if (emptyIndex !== -1)
      document.getElementById(`passcode-${emptyIndex}`).focus()
  }

  useEffect(() => {
    if (!passcodeKeys.some((key) => key === '')) onSubmit()
    setFocus(passcodeKeys)
  }, [onSubmit, passcodeKeys])

  return (
    <FullPageWrapper>
      <BackgroundImage />
      <Title />
      <PasscodeInputWrapper>
        {[...Array(PASSCODE.length)].map((x, i) => (
          <PasscodeInput
            id={i}
            key={i}
            onChange={handleChange}
            onDelete={handleDelete}
            onFocus={handleFocus}
            value={passcodeKeys[i]}
          />
        ))}
      </PasscodeInputWrapper>
    </FullPageWrapper>
  )
}

export default LoginForm
