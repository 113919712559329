import styled, { css } from 'styled-components'
import Theme from '../Theme'

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: ${Theme.site.maxWidth.get()};
  padding: 0 1rem;
  ${(props) =>
    (props.clearNav || props.clearNav === 0) &&
    css`
      margin-top: ${Theme.navbar.height.get(props.clearNav)};
    `}
  position: relative;
`

export default Container
