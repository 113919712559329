import styled from 'styled-components'
import kiss from './../assets/images/kiss.jpg'

const BackgroundImage = styled.div`
  background-image: url(${kiss});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(6px);
  height: 100vh;
  opacity: 0.8;
  position: absolute;
  transform: scale(1.1);
  width: 100%;
  z-index: -100;

  @media (max-width: 768px) {
    background-position: left;
  }
`
export default BackgroundImage
