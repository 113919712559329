import styled from 'styled-components'

const FullPageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
`

export default FullPageWrapper
