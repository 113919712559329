import React from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'
import BuzzfeedCard from './BuzzfeedCard'
import Container from '../Container'
import Headline from './Headline'
import Honeybee from '../Honeybee'
import Memoji from './Memoji'
import Navbar from '../Nav/Navbar'
import { BuzzFeedData as data } from '../../data'
import koala from './../../assets/images/koala.gif'

const BuzzFeedLogo = styled.h1`
  color: #e32;
  display: inline-block;
  font-size: 1.8rem;
  margin: 0;
`

const Buzzfeed = () => {
  return (
    <Container clearNav={2}>
      <Navbar logo={<BuzzFeedLogo>BuzzFeed</BuzzFeedLogo>} />
      <Headline>
        13 Reasons Alicia's Husband Is So Very, Truly, Madly, Deeply In Love
        With Her...Happy Anniversary, <Honeybee />
      </Headline>
      <Avatar />
      {data.map((item, index) => (
        <BuzzfeedCard
          imgUrl={item.imgUrl}
          key={index}
          number={index + 1}
          title={item.title}
        >
          {item.content}
        </BuzzfeedCard>
      ))}
      <BuzzfeedCard imgUrl={koala} key='koala' title='Koala bonus!!!' />
      <Memoji />
    </Container>
  )
}

export default Buzzfeed
