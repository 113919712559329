import React, { useEffect, useState } from 'react'
import anime from 'animejs'
import styled from 'styled-components'
import { useConnections, useConnectionsDispatch } from './ConnectionsContext'
import { ACTIONS } from '../../constants'

const ConnectionsCardStyled = styled.div`
  align-items: center;
  background-color: rgb(239, 239, 230);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  &:active {
    transition: 0.3s all ease-in-out;
    transform: scale(0.9);
  }

  &.selected,
  &.solved {
    background-color: rgb(90, 89, 78);
    color: rgb(255, 255, 255);
  }
`

const ConnectionsCard = ({ card }) => {
  const state = useConnections()
  const dispatch = useConnectionsDispatch()

  const [prevMistakes, setPrevMistakes] = useState(state.mistakes)

  const handleClick = (card) => {
    dispatch({
      type: card.isSelected
        ? ACTIONS.CONNECTIONS.DESELECT_CARD
        : ACTIONS.CONNECTIONS.SELECT_CARD,
      card,
    })
  }

  useEffect(() => {
    if (state.lastAction === ACTIONS.CONNECTIONS.SUBMIT) {
      anime
        .timeline({
          delay: anime.stagger(50),
          duration: 800,
          targets: ['.selected', '.solved'],
        })
        .add({ translateY: -20 })
        .add({ translateY: 0 }, '-=750')

      if (state.mistakes !== prevMistakes) {
        anime
          .timeline({
            duration: 600,
            targets: '.selected',
          })
          .add({ delay: 900, opacity: 0.8 })
          .add({ duration: 75, easing: 'easeInOutBack', translateX: -12 })
          .add({ duration: 75, easing: 'easeInOutBack', translateX: 12 })
          .add({ duration: 75, easing: 'easeInOutBack', translateX: -12 })
          .add({ duration: 75, easing: 'easeInOutBack', translateX: 0 })
          .add({ delay: 350, opacity: 1 })
        setPrevMistakes(state.mistakes)
      } else {
        anime
          .timeline({
            duration: 600,
            targets: '.solved',
            complete: (anim) => {
              if (anim.completed && anim.animatables.length > 0)
                anim.animatables.forEach(
                  (el) => (el.target.style.display = 'none')
                )
            },
          })
          .add({ delay: 900, opacity: 0 })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction, state.mistakes])

  return (
    <ConnectionsCardStyled
      className={`${card.isSelected ? 'selected' : ''}${
        card.isSolved ? 'solved' : ''
      }`}
      onClick={() => handleClick(card)}
    >
      {card.value}
    </ConnectionsCardStyled>
  )
}

export default ConnectionsCard
