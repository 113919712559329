import { createContext, useContext, useReducer } from 'react'
import { ACTIONS } from '../../constants'
import { shuffle } from 'lodash'
import { colorsMatch, isOneAway, isSubmitted } from './utils'
import { CONNECTIONS } from '../../constants'
import { ConnectionsData as data } from '../../data'

const ConnectionsContext = createContext(null)

const ConnectionsDispatchContext = createContext(null)

export function ConnectionsProvider({ children }) {
  const [state, dispatch] = useReducer(connectionsReducer, initialState)

  return (
    <ConnectionsContext.Provider value={state}>
      <ConnectionsDispatchContext.Provider value={dispatch}>
        {children}
      </ConnectionsDispatchContext.Provider>
    </ConnectionsContext.Provider>
  )
}

export function useConnections() {
  return useContext(ConnectionsContext)
}

export function useConnectionsDispatch() {
  return useContext(ConnectionsDispatchContext)
}

function connectionsReducer(state, action) {
  switch (action.type) {
    case ACTIONS.CONNECTIONS.CLEAR_NOTIFICATION:
      return {
        ...state,
        lastAction: ACTIONS.CONNECTIONS.CLEAR_NOTIFICATION,
        notification: { message: '' },
      }

    case ACTIONS.CONNECTIONS.DESELECT_ALL_CARDS:
      return {
        ...state,
        cards: state.cards.map((card) => ({ ...card, isSelected: false })),
        lastAction: ACTIONS.CONNECTIONS.DESELECT_ALL_CARDS,
        selected: [],
      }

    case ACTIONS.CONNECTIONS.DESELECT_CARD:
      const selectedCard = { ...action.card, isSelected: false }
      return {
        ...state,
        cards: state.cards.map((card) =>
          card.value === selectedCard.value ? selectedCard : card
        ),
        lastAction: ACTIONS.CONNECTIONS.DESELECT_CARD,
        selected: state.selected.filter(
          (card) => card.value !== selectedCard.value
        ),
      }

    case ACTIONS.CONNECTIONS.SELECT_CARD:
      if (state.selected.length < CONNECTIONS.MAX_SELECTED) {
        const selectedCard = { ...action.card, isSelected: true }
        return {
          ...state,
          cards: state.cards.map((card) =>
            card.value === selectedCard.value ? selectedCard : card
          ),
          lastAction: ACTIONS.CONNECTIONS.SELECT_CARD,
          selected: [...state.selected, selectedCard],
        }
      }
      return state

    case ACTIONS.CONNECTIONS.SHUFFLE_CARDS:
      return {
        ...state,
        cards: shuffle(state.cards),
        lastAction: ACTIONS.CONNECTIONS.SHUFFLE_CARDS,
      }

    case ACTIONS.CONNECTIONS.SUBMIT:
      const isMatch = state.selected.every(colorsMatch)
      const wasSubmitted = isSubmitted(state.selected, state.submitted)
      if (isMatch) {
        // Handle case when all selected cards match (are the same color)
        const selectedCardValues = state.selected.map((card) => card.value)
        return {
          ...state,
          cards: state.cards.map((card) =>
            selectedCardValues.includes(card.value)
              ? { ...card, isSelected: false, isSolved: true }
              : card
          ),
          lastAction: ACTIONS.CONNECTIONS.SUBMIT,
          notification: {
            message:
              state.solveCount + 1 === CONNECTIONS.MAX_SOLVE_COUNT
                ? CONNECTIONS.END_MESSAGE[state.mistakes]
                : '',
          },
          selected: [],
          solveCount: state.solveCount + 1,
          solved: state.solved
            .map((set) => {
              if (set.color === state.selected[0].color)
                return {
                  ...set,
                  isSolved: true,
                  solveOrder: state.solveCount + 1,
                }
              return set
            })
            .sort((a, b) => a.solveOrder - b.solveOrder),
          submitted: [...state.submitted, state.selected],
        }
      } else if (!wasSubmitted) {
        const mistakes = state.mistakes + 1
        if (mistakes < CONNECTIONS.MAX_MISTAKES) {
          // Handle case when there are mistakes remaining and were not already submitted
          return {
            ...state,
            lastAction: ACTIONS.CONNECTIONS.SUBMIT,
            mistakes,
            notification: {
              message: isOneAway(state.selected) ? 'One away...' : '',
            },
            submitted: [...state.submitted, state.selected],
          }
        } else {
          // Handle case when there are no mistakes remaining and reveal the solution
          return {
            ...state,
            cards: [],
            lastAction: ACTIONS.CONNECTIONS.SUBMIT,
            mistakes,
            notification: { message: CONNECTIONS.END_MESSAGE[mistakes] },
            selected: [],
            solved: state.solved
              .map((set) => ({
                ...set,
                isSolved: true,
                solveOrder: set.solveOrder || 10,
              }))
              .sort((a, b) => a.solveOrder - b.solveOrder),
            submitted: [...state.submitted, state.selected],
          }
        }
      } else {
        // Handle case when selected cards do not match and were already submitted
        return {
          ...state,
          lastAction: '', // no action is taken when submitting a duplicate response
          notification: { message: 'Already guessed!' },
        }
      }

    default:
      return state
  }
}

const initialState = {
  cards: shuffle(
    data
      .map((set) =>
        set.items.map((item) => ({
          color: set.color,
          isSelected: false,
          isSolved: false,
          title: set.title,
          value: item,
        }))
      )
      .flat()
  ),
  lastAction: '',
  mistakes: 0,
  notification: { message: '' },
  selected: [],
  solveCount: 0,
  solved: data.map((set) => ({
    ...set,
    isSolved: false,
    items: set.items.sort().join(', '),
    solveOrder: 0,
  })),
  submitted: [],
}
