import React from 'react'
import styled from 'styled-components'
import Appear from '../Animations/Appear'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 2rem;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  width: 95vw;
`

const FailedLogin = () => (
  <Appear>
    <Wrapper>Clearly, you do not belong here!</Wrapper>
  </Appear>
)

export default FailedLogin
