import React from 'react'
import styled from 'styled-components'
import Appear from '../Animations/Appear'
import Theme from '../../Theme'

const Caption = styled.p`
  font-size: 1.125rem;
  line-height: 1.5rem;
`

const Container = styled.div`
  margin-bottom: 3rem;
  max-width: 600px;
  min-height: 90vh;
  scroll-margin-top: ${Theme.navbar.height.get()};
  scroll-snap-align: center;
  scroll-snap-stop: always;
  width: 100%;

  @meida (max-width: 768px) {
    max-height: 100vh;
  }
`

const Image = styled.img`
  border-radius: 8px;
  display: block;
  max-height: 500px;
  max-width: 100%;
`

const Title = styled.h2`
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.2;
`

const BuzzfeedCard = ({ children, imgUrl, number, title }) => {
  return (
    <Container>
      <Appear duration={1800}>
        <Title>
          {number && `${number}. `} {title}
        </Title>
        <Image src={imgUrl} />
        <Caption>{children}</Caption>
      </Appear>
    </Container>
  )
}

export default BuzzfeedCard
