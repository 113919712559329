import styled from 'styled-components'
import ConnectionsCard from './ConnectionsCard'
import SolvedCard from './SolvedCard'
import { useConnections } from './ConnectionsContext'

const Board = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: calc(3 * 8px + 4 * 80px);
  margin: 24px auto;
  width: calc(3 * 8px + 4 * 150px);

  @media (max-width: 768px) {
    width: 100%;
  }
`

const ConnectionsBoard = () => {
  const state = useConnections()

  return (
    <Board>
      {state.solved
        .filter((set) => set.isSolved)
        .map((set) => (
          <SolvedCard set={set} key={set.color} />
        ))}
      {state.cards.map((card) => (
        <ConnectionsCard card={card} key={card.value} />
      ))}
    </Board>
  )
}

export default ConnectionsBoard
