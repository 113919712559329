import cold from './assets/images/cold.jpeg'
import fridge from './assets/images/fridge.png'
import grout from './assets/images/grout.jpeg'
import house from './assets/images/house.png'
import inlaws from './assets/images/inlaws.jpeg'
import jokes from './assets/images/jokes.jpeg'
import kiss from './assets/images/kiss.jpg'
import makeout from './assets/images/chem-cat-makeout-meme.jpeg'
import parking from './assets/images/parking.png'
import salmon from './assets/images/salmon.jpeg'
import stress from './assets/images/stress.jpeg'
import sugar from './assets/images/sugar.jpg'
import toothbrush from './assets/images/toothbrush.jpg'
import vows from './assets/images/vows.jpg'

export const BuzzFeedData = [
  {
    content: '',
    imgUrl: cold, // meme
    title:
      'I love that despite living in the frigid cold of Montana you cannot handle the cold of a Las Vegas winter',
  },
  {
    content: '',
    imgUrl: house, // picture
    title:
      'I love that in the midst of all you have to think about you also find the bandwidth to think about making our house smell good',
  },
  {
    content: '',
    imgUrl: grout, // meme
    title:
      'I love that when presented with the idea of sex in the kitchen your mind immediately fixates on the cleanliness of the grout',
  },
  {
    content: '',
    imgUrl: parking, // picture
    title: 'I love your eccentric parking patterns in our driveway',
  },
  {
    content: '',
    imgUrl: makeout, // meme
    title:
      'I love that we can be making out on the couch and you’re thinking about what to wear to my holiday party',
  },
  {
    content: '',
    imgUrl: stress, // meme
    title:
      'I love that you stress out for me about things I should be stressing about',
  },
  {
    content: '',
    imgUrl: toothbrush, // picture
    title: 'I love that you leave the toothbrush wet when you’re done using it',
  },
  {
    content: '',
    imgUrl: fridge, // picture
    title:
      'I love that you’re willing to deal with my water mess on the fridge',
  },
  {
    content: '',
    imgUrl: inlaws, // meme
    title:
      'I love that you took a “sick” day to clean all the shit (figurative and, unfortunately, literal) that my family left behind',
  },
  {
    content: '',
    imgUrl: vows, // picture
    title: 'I love that you wrote a 10-point list for your wedding vows to me',
  },
  {
    content: '',
    imgUrl: jokes, // meme
    title:
      'I love that you crack up at my most ridiculous and terrible dad jokes',
  },
  {
    content: '',
    imgUrl: salmon, // meme
    title:
      'I love that in the middle of shenanigans you’re thinking of what to do with the leftover salmon',
  },
  {
    content: '',
    imgUrl: sugar, // picture
    title:
      'I love that you don’t judge me for eating the occasional spoonful of sugar',
  },
  {
    content: '',
    imgUrl: kiss, // picture
    title:
      'Okay, fourteen reasons...I love that you chose me to be your honeybee, your person, your love for the rest of our lives ♥',
  },
]

////
// Connections
////
export const ConnectionsDataDev = [
  {
    color: 'yellow',
    title: 'Yellow',
    items: ['y3', 'y4', 'y2', 'y1'],
  },
  {
    color: 'green',
    title: 'Green',
    items: ['g4', 'g1', 'g3', 'g2'],
  },
  {
    color: 'blue',
    title: 'Blue',
    items: ['b1', 'b2', 'b3', 'b4'],
  },
  {
    color: 'purple',
    title: 'Purple',
    items: ['p1', 'p2', 'p3', 'p4'],
  },
]

export const ConnectionsData = [
  {
    color: 'yellow',
    title: "Alicia's Favorites",
    items: ['Cheese', 'Klimpt', 'Cake', 'TikTok'],
  },
  {
    color: 'green',
    title: 'Memorable Songs',
    items: ['Ophelia', 'Honeybee', 'Swan', 'Almost'],
  },
  {
    color: 'blue',
    title: 'Things Your Husband Loves About You',
    items: ['Eyes', 'Smile', 'Booty', 'Laugh'],
  },
  {
    color: 'purple',
    title: 'Important Places Without The First Word',
    items: ['York', 'Lucia', 'Vegas', 'Monica'],
  },
]
