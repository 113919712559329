import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { Modal } from 'react-responsive-modal'
import RoundedButton from './RoundedButton'
import { useConnections } from './ConnectionsContext'
import { sleep } from './utils'
import { CONNECTIONS } from '../../constants'
import Theme from '../../Theme'

import '../css/results-modal.css'
import 'react-responsive-modal/styles.css'

const CloseButtonStyled = styled.div`
  &:hover {
    text-decoration: underline;
  }

  & > svg {
    margin-left: 10px;
  }
`

const ResultBlock = styled.div`
  border-radius: 5px;
  height: 40px;
  width: 40px;

  &.yellow {
    background-color: ${Theme.palette.yellow};
  }

  &.green {
    background-color: ${Theme.palette.green};
  }

  &.blue {
    background-color: ${Theme.palette.blue};
  }

  &.purple {
    background-color: ${Theme.palette.purple};
  }
`

const ResultHeader = styled.h2`
  font-family: Georgia, serif;
  font-size: 36px;
  line-height: 36px;
  margin: 8px 0;
`

const ResultRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 8px;
`

const ResultSubheader = styled.h3`
  font-weight: 500;
  line-height: 20px;
  margin: 20px 0;
`

const ResultWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const CloseButton = (
  <CloseButtonStyled>
    Back to puzzle
    <FontAwesomeIcon icon={faClose} size='lg' />
  </CloseButtonStyled>
)

const ResultsModal = () => {
  const state = useConnections()
  const [open, setOpen] = useState(false)

  const onClose = () => setOpen(false)

  const onOpen = () => setOpen(true)

  useEffect(() => {
    async function showModal() {
      if (
        state.mistakes === CONNECTIONS.MAX_MISTAKES ||
        state.solveCount === CONNECTIONS.MAX_SOLVE_COUNT
      ) {
        await sleep(2000)
        setOpen(true)
      }
    }
    showModal()
  }, [state.mistakes, state.solveCount])

  return (
    <>
      <RoundedButton onClick={onOpen}>View results</RoundedButton>
      <Modal
        center
        classNames={{
          modalContainer: 'container',
          modal: 'modal',
          overlay: 'overlay',
        }}
        closeIcon={CloseButton}
        onClose={onClose}
        open={open}
      >
        <ResultWrapper>
          <ResultHeader>
            {CONNECTIONS.END_MESSAGE[state.mistakes]}!
          </ResultHeader>
          <ResultSubheader>Connections #4321</ResultSubheader>
          {state.submitted.map((set) => (
            <ResultRow>
              {set.map((card) => (
                <ResultBlock className={card.color} />
              ))}
            </ResultRow>
          ))}
        </ResultWrapper>
      </Modal>
    </>
  )
}

export default ResultsModal
