import React, { useState } from 'react'
import ActivitySelector from './components/ActivitySelector'
import Login from './components/Login/Login'
import { NavProvider } from './components/Nav/NavContext'

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  return isLoggedIn ? (
    <NavProvider>
      <ActivitySelector />
    </NavProvider>
  ) : (
    <Login onLogin={setIsLoggedIn} />
  )
}

export default App
