import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { sleep } from './utils'
import Theme from '../../Theme'

const TIME = {
  DELAY: 1400,
  DURATION: 500,
}

const SolvedCardStyled = styled.div`
  align-items: center;
  border-radius: 6px;
  display: none;
  flex-flow: column nowrap;
  font-size: 20px;
  grid-column: 1 / span 4;
  justify-content: center;
  text-transform: uppercase;
  transition: all ${TIME.DURATION}ms ease-in-out;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  &.enter-active {
    display: flex;
    transform: scale(1.2);
  }

  &.enter-done {
    display: flex;
  }

  &.yellow {
    background-color: ${Theme.palette.yellow};
  }

  &.green {
    background-color: ${Theme.palette.green};
  }

  &.blue {
    background-color: ${Theme.palette.blue};
  }

  &.purple {
    background-color: ${Theme.palette.purple};
  }

  & > h4,
  p {
    margin: 0;
  }
`

const SolvedCard = ({ set }) => {
  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)

  useEffect(() => {
    async function showCard() {
      if (set.isSolved) {
        await sleep(TIME.DELAY)
        setInProp(set.isSolved)
      }
    }
    showCard()
  }, [set.isSolved])

  return (
    <CSSTransition in={inProp} nodeRef={nodeRef} timeout={TIME.DURATION}>
      <SolvedCardStyled className={set.color} ref={nodeRef}>
        <h4>{set.title}</h4>
        <p>{set.items}</p>
      </SolvedCardStyled>
    </CSSTransition>
  )
}

export default SolvedCard
