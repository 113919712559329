import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ResultsModal from './ResultsModal'
import RoundedButton from './RoundedButton'
import { useConnections, useConnectionsDispatch } from './ConnectionsContext'
import { ACTIONS, CONNECTIONS } from '../../constants'

const Bar = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`

const ButtonBar = () => {
  const state = useConnections()
  const dispatch = useConnectionsDispatch()

  const [prevMistakes, setPrevMistakes] = useState(state.mistakes)
  const [mistakeSubmitted, setMistakeSubmitted] = useState(false)

  useEffect(() => {
    if (state.lastAction === ACTIONS.CONNECTIONS.SUBMIT) {
      if (state.mistakes !== prevMistakes) {
        setPrevMistakes(state.mistakes)
        setMistakeSubmitted(true)
      }
    } else {
      setMistakeSubmitted(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction, state.mistakes])

  if (
    state.mistakes < CONNECTIONS.MAX_MISTAKES &&
    state.solveCount < CONNECTIONS.MAX_SOLVE_COUNT
  ) {
    return (
      <Bar>
        <RoundedButton
          onClick={() => dispatch({ type: ACTIONS.CONNECTIONS.SHUFFLE_CARDS })}
        >
          Shuffle
        </RoundedButton>
        <RoundedButton
          disabled={state.selected.length === 0}
          onClick={() =>
            dispatch({ type: ACTIONS.CONNECTIONS.DESELECT_ALL_CARDS })
          }
        >
          Deselect all
        </RoundedButton>
        <RoundedButton
          className='black'
          disabled={state.selected.length !== 4 || mistakeSubmitted}
          onClick={() => dispatch({ type: ACTIONS.CONNECTIONS.SUBMIT })}
        >
          Submit
        </RoundedButton>
      </Bar>
    )
  } else {
    return (
      <Bar>
        <ResultsModal />
      </Bar>
    )
  }
}

export default ButtonBar
