import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useIsVisible } from 'react-is-visible'

const Appear = ({ children, duration = 1400, percent = '-4%', style }) => {
  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef, { once: true })

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setInProp(isVisible)
  }, [isVisible])

  const defaultStyle = {
    height: '100%',
    opacity: 0,
    transition: `opacity ${duration}ms ease-in-out, transform ${
      duration * 0.6
    }ms ease-in-out`,
    transform: `translate(${percent})`,
    width: '100%',
  }

  const transitionStyles = {
    entering: { opacity: 1, transform: 'translate(0%)' },
    entered: { opacity: 1, transform: 'translate(0%)' },
    exiting: { opacity: 0, transform: `translate(${percent})` },
    exited: { opacity: 0, transform: `translate(${percent})` },
  }

  return (
    <Transition in={inProp} timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{ ...defaultStyle, ...transitionStyles[state], ...style }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

export default Appear
