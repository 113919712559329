import React from 'react'
import styled from 'styled-components'
import Appear from '../Animations/Appear'
import Hovertip from '../Hovertip'
import memoji from './../../assets/images/memoji.png'
import { useWindowDimensions } from '../../hooks'

const Image = styled.img`
  width: 100%;
`

const Memoji = () => {
  const { width } = useWindowDimensions()

  return (
    <Appear
      duration={15000}
      style={{
        bottom: '-0.1%',
        height: '300px',
        position: 'absolute',
        right: width > 768 ? '67%' : '12%',
        width: '300px',
      }}
    >
      <Hovertip content='Happy anniversary! I love you!'>
        <Image src={memoji} />
      </Hovertip>
    </Appear>
  )
}

export default Memoji
