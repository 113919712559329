import React from 'react'
import ConnectionsGame from './ConnectionsGame'
import ConnectionsLogo from './ConnectionsLogo'
import Container from '../Container'
import Navbar from '../Nav/Navbar'
import Notifier from './Notifier'
import { ConnectionsProvider } from './ConnectionsContext'

import 'react-toastify/dist/ReactToastify.css'

const Connections = () => (
  <Container clearNav={2}>
    <Navbar logo={<ConnectionsLogo />} />
    <ConnectionsProvider>
      <Notifier />
      <ConnectionsGame />
    </ConnectionsProvider>
  </Container>
)

export default Connections
