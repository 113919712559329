import React from 'react'
import styled from 'styled-components'
import BackgroundImage from './BackgroundImage'
import FullPageWrapper from './FullPageWrapper'
import Title from './Title'
import { ACTIVITIES } from '../constants'
import buzzfeed from './../assets/images/buzzfeed.png'
import connections from './../assets/images/connections.png'

const ActivityCard = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 40vh;
  justify-content: center;
  width: 40%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 768px) {
    height: 30vh;
    margin-top: 24px;
    width: 100%;
  }

  & > img {
    width: 80%;
  }
`

const CardWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 80%;

  @media (max-width: 768px) {
    display: block;
    width: 90%;
  }
`

const Dashboard = ({ onSelection }) => {
  return (
    <FullPageWrapper>
      <BackgroundImage />
      <Title plain />
      <CardWrapper>
        <ActivityCard onClick={onSelection(ACTIVITIES.BUZZFEED)}>
          <img src={buzzfeed} alt='BuzzFeed logo' />
        </ActivityCard>
        <ActivityCard onClick={onSelection(ACTIVITIES.CONNECTIONS)}>
          <img src={connections} alt='Connections logo' />
        </ActivityCard>
      </CardWrapper>
    </FullPageWrapper>
  )
}

export default Dashboard
