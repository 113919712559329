import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import { ACTIONS, ACTIVITIES } from '../constants'
import { useNavDispatch } from './Nav/NavContext'
import IconButton from './IconButton'

const HomeButton = () => {
  const navDispatch = useNavDispatch()

  const handleClick = () =>
    navDispatch({
      type: ACTIONS.NAV.SELECT_ACTIVITY,
      activity: ACTIVITIES.DASHBOARD,
    })

  return (
    <IconButton>
      <FontAwesomeIcon
        icon={faHome}
        onClick={handleClick}
        size='lg'
        fixedWidth
      />
    </IconButton>
  )
}

export default HomeButton
