import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import anime from 'animejs'
import { useConnections } from './ConnectionsContext'
import { CONNECTIONS } from '../../constants'

const Tracker = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  & > div {
    align-items: center;
    display: flex;
    min-width: calc(
      ${CONNECTIONS.MAX_MISTAKES} * 10px + ${CONNECTIONS.MAX_MISTAKES} * 16px
    );

    & > span {
      background-color: rgb(90, 89, 78);
      border-radius: 100%;
      display: inline-block;
      height: 16px;
      margin-left: 10px;
      width: 16px;

      &.hidden {
        display: none;
      }
    }
  }
`

const MistakeTracker = () => {
  const state = useConnections()
  const [prevMistakes, setPrevMistakes] = useState(state.mistakes)

  useEffect(() => {
    if (state.mistakes !== prevMistakes) {
      const el = document.getElementById(`mt-${state.mistakes}`)
      if (el) el.className = 'hide'
      anime
        .timeline({
          targets: '.hide',
          complete: (anim) => {
            if (anim.animatables.length > 0)
              anim.animatables[0].target.className = 'hidden'
          },
        })
        .add({
          delay: 2150,
          duration: 400,
          scale: 1.3,
        })
        .add({
          duration: 1400,
          scale: 0,
        })
      setPrevMistakes(state.mistakes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.mistakes])

  if (
    state.mistakes < CONNECTIONS.MAX_MISTAKES &&
    state.solveCount < CONNECTIONS.MAX_SOLVE_COUNT
  ) {
    return (
      <Tracker>
        Mistakes remaining:
        <div>
          <span id='mt-4'></span>
          <span id='mt-3'></span>
          <span id='mt-2'></span>
          <span id='mt-1'></span>
        </div>
      </Tracker>
    )
  } else {
    return null
  }
}

export default MistakeTracker
