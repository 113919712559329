class Dimension {
  constructor(value, unit) {
    this.value = value
    this.unit = unit
    this.get = (shift) => this.value + (shift || 0) + this.unit
  }
}

const Theme = {
  navbar: {
    height: new Dimension(4, 'rem'),
  },
  palette: {
    yellow: 'rgb(249, 223, 109)',
    green: 'rgb(160, 195, 90)',
    blue: 'rgb(176, 196, 239)',
    purple: 'rgb(186, 129, 197)',
  },
  site: {
    maxWidth: new Dimension(71, 'rem'),
  },
}

export default Theme
