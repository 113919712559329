import styled from 'styled-components'

const ConnectionsLogoStyled = styled.h1`
  display: inline-block;
  font-family: Georgia, serif;
  font-size: 1.8rem;
  margin: 0;
`

const ConnectionsLogo = () => (
  <ConnectionsLogoStyled>Connections</ConnectionsLogoStyled>
)

export default ConnectionsLogo
