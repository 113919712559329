import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faMusicSlash } from '@fortawesome/pro-solid-svg-icons'
import IconButton from './IconButton'
import { useNav, useNavDispatch } from './Nav/NavContext'
import { ACTIONS } from '../constants'

const useAudio = (src, autoplay) => {
  const state = useNav()
  const dispatch = useNavDispatch()

  const [audio] = useState(state.audio || new Audio(src))
  const [playing, setPlaying] = useState(autoplay || false)

  const toggle = () => setPlaying(!playing)

  useEffect(() => {
    playing ? audio.play() : audio.pause()
    if (!state.audio) dispatch({ type: ACTIONS.NAV.SET_AUDIO, audio })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))

    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [audio])

  return [playing, toggle]
}

const AudioPlayer = ({ autoplay, src }) => {
  const [playing, toggle] = useAudio(src, autoplay)

  return (
    <IconButton onClick={toggle}>
      {playing ? (
        <FontAwesomeIcon icon={faMusic} size='lg' fixedWidth />
      ) : (
        <FontAwesomeIcon icon={faMusicSlash} size='lg' fixedWidth />
      )}
    </IconButton>
  )
}

export default AudioPlayer
