import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { useConnections, useConnectionsDispatch } from './ConnectionsContext'
import { ACTIONS } from '../../constants'

import '../css/notifier.css'

const Notifier = () => {
  const state = useConnections()
  const dispatch = useConnectionsDispatch()

  useEffect(() => {
    if (state.notification.message !== '') {
      toast(state.notification.message)
      dispatch({ type: ACTIONS.CONNECTIONS.CLEAR_NOTIFICATION })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.notification])

  return (
    <ToastContainer
      autoClose={1500}
      closeButton={false}
      draggable={false}
      hideProgressBar
      limit={1}
      pauseOnHover={false}
      position='top-center'
      style={{
        left: '50%',
        textAlign: 'center',
        top: '16px',
        transform: 'translateX(-50%)',
        width: 'auto',
      }}
      theme='dark'
      toastClassName='toast-rounded'
    />
  )
}

export default Notifier
