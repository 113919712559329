export const ACTIONS = {
  CONNECTIONS: {
    CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
    DESELECT_ALL_CARDS: 'DESELECT_ALL_CARDS',
    DESELECT_CARD: 'DESELECT_CARD',
    SELECT_CARD: 'SELECT_CARD',
    SHUFFLE_CARDS: 'SHUFFLE_CARDS',
    SUBMIT: 'SUBMIT',
  },
  NAV: {
    SELECT_ACTIVITY: 'SELECT_ACTIVITY',
    SET_AUDIO: 'SET_AUDIO',
  },
}

export const ACTIVITIES = {
  BUZZFEED: 'buzzfeed',
  CONNECTIONS: 'connections',
  DASHBOARD: 'dashboard',
}

export const COLORS = {
  BLUE: '#60a3dd',
  PURPLE: '#9a60dd',
}

export const CONNECTIONS = {
  END_MESSAGE: ['Perfect', 'Great', 'Solid', 'Phew', 'Next Time'],
  MAX_MISTAKES: 4,
  MAX_SELECTED: 4,
  MAX_SOLVE_COUNT: 4,
}

export const PASSCODE = 'bumble'
