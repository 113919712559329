import React from 'react'
import Buzzfeed from './Buzzfeed/Buzzfeed'
import Connections from './Connections/Connections'
import Dashboard from './Dashboard'
import { useNav, useNavDispatch } from './Nav/NavContext'
import { ACTIONS, ACTIVITIES } from '../constants'

const ActivitySelector = () => {
  const nav = useNav()
  const navDispatch = useNavDispatch()

  const handleSelection = (val) => (e) =>
    navDispatch({ type: ACTIONS.NAV.SELECT_ACTIVITY, activity: val })

  switch (nav.activity) {
    case ACTIVITIES.BUZZFEED:
      return <Buzzfeed />
    case ACTIVITIES.CONNECTIONS:
      return <Connections />
    case ACTIVITIES.DASHBOARD:
      return <Dashboard onSelection={handleSelection} />
    default:
      return <Dashboard onSelection={handleSelection} />
  }
}

export default ActivitySelector
