import React from 'react'
import Tooltip from 'react-simple-tooltip'
import { COLORS } from '../constants'

const Hovertip = ({ children, content }) => {
  return (
    <Tooltip
      arrow={23}
      background={COLORS.BLUE}
      border={COLORS.PURPLE}
      color={COLORS.PURPLE}
      content={content}
      fadeDuration={600}
      fontFamily='ProximaNova'
      fontSize='1.2rem'
      radius={8}
    >
      {children}
    </Tooltip>
  )
}

export default Hovertip
