import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBee } from '@fortawesome/pro-solid-svg-icons'

const Spinner = styled.div`
  cursor: alias;
  display: inline-block;
  transition: transform 0.7s ease-in-out;

  &:hover {
    transform: rotate(360deg);
  }
`

const Honeybee = () => {
  return (
    <Spinner>
      <FontAwesomeIcon icon={faBee} />
    </Spinner>
  )
}

export default Honeybee
