import React, { useState } from 'react'
import FailedLogin from './FailedLogin'
import LoginForm from './LoginForm'
import { PASSCODE } from '../../constants'

const Login = ({ onLogin }) => {
  const [passcode, setPasscode] = useState('')
  const [isFailedLogin, setIsFailedLogin] = useState(false)

  const handleLogin = () => {
    if (passcode === PASSCODE) {
      onLogin(true)
    } else {
      setIsFailedLogin(true)
    }
  }

  return isFailedLogin ? (
    <FailedLogin />
  ) : (
    <LoginForm onChange={setPasscode} onSubmit={handleLogin} />
  )
}

export default Login
