import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { debounce } from 'underscore'
import AudioPlayer from '../AudioPlayer'
import Container from '../Container'
import HomeButton from '../HomeButton'
import Theme from '../../Theme'
import songFile from './../../assets/audio/Honeybee.mp3'

const Nav = styled.nav`
  background-color: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  height: ${Theme.navbar.height.get()};
  left: 0;
  position: fixed;
  top: 0;
  transition: top 0.6s;
  width: 100%;
  z-index: 500;

  &.hidden {
    top: -${Theme.navbar.height.get(1)};
  }
`

const NavButtons = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;

  & > div {
    margin-left: 24px;
  }
`

const NavContents = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`

const Navbar = ({ logo }) => {
  const [prevScrollPosn, setPrevScrollPosn] = useState(0)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const handleScroll = debounce(() => {
      const currentScrollPosn = window.pageYOffset
      setVisible(
        (prevScrollPosn > currentScrollPosn &&
          prevScrollPosn - currentScrollPosn > 70) ||
          currentScrollPosn < 10
      )
      setPrevScrollPosn(currentScrollPosn)
    }, 100)

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPosn, visible])

  return (
    <Nav className={!visible && 'hidden'}>
      <Container>
        <NavContents>
          {logo}
          <NavButtons>
            <AudioPlayer src={songFile} autoplay />
            <HomeButton />
          </NavButtons>
        </NavContents>
      </Container>
    </Nav>
  )
}

export default Navbar
