import { isEqual, sortBy } from 'lodash'

export const colorsMatch = (element, index, array) => {
  if (index > 0) return element.color === array[index - 1].color
  return true
}

export const isOneAway = (cards) =>
  Object.values(
    cards.reduce(
      (sortedCards, card) => {
        sortedCards[card.color] += 1
        return sortedCards
      },
      { blue: 0, green: 0, purple: 0, yellow: 0 }
    )
  ).some((count) => count === 3)

export const isSubmitted = (needle, haystack) => {
  return haystack
    .map((stack) => isEqual(sortBy(stack, 'value'), sortBy(needle, 'value')))
    .includes(true)
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
